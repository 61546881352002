import { TFunction } from 'i18next'
import { amountToLocaleString } from './stripe/amount-to-locale-string'
import { Plan } from './stripe/get-plan'

export function getPaymentSummary(t: TFunction, language: string, plan?: Plan) {
  if (!plan) {
    return
  }

  let key = 'common.plan.paymentSummary'

  if (plan.coupon?.type === 'b2b' && plan.coupon.name) {
    key = 'common.bcAccountWithName'
  } else if (plan.coupon?.type === 'b2b') {
    key = 'common.bcAccount'
  } else if (plan.firstPeriodDiscounted && plan.freeTrialDays) {
    key = 'common.plan.paymentSummaryFirstPeriodDiscountFreeTrial'
  } else if (plan.firstPeriodDiscounted) {
    key = 'common.plan.paymentSummaryFirstPeriodDiscount'
  } else if (plan.freeTrialDays && plan.upfrontPaymentRequired) {
    key = 'common.plan.paymentSummaryFreeTrial'
  } else if (plan.freeTrialDays && !plan.upfrontPaymentRequired) {
    key = 'common.plan.paymentSummaryFreeTrialNoPayment'
  }

  return t(key, {
    total: amountToLocaleString(plan.amount.total, plan.currency, language),
    subtotal: amountToLocaleString(plan.amount.subtotal, plan.currency, language),
    period: t(`common.plan.period.${plan.interval}`),
    interval: t(`common.plan.interval.${plan.interval}`),
    count: plan.freeTrialDays,
    couponName: plan.coupon?.name
  })
}
