// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Message.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Message.tsx");
}
// REMIX HMR END

import { Heading } from './Heading';
import { Text } from './Text';
import styles from './Message.module.css';
import clsx from 'clsx';
export function Message({
  type,
  title,
  message
}) {
  return <div className={clsx(styles.message, styles[type])}>
      <Heading level={3}>{title}</Heading>

      <Text as="p" size="lg">
        {message}
      </Text>
    </div>;
}
_c = Message;
var _c;
$RefreshReg$(_c, "Message");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;